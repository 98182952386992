import styled from 'styled-components';

export const TableContainer = styled.div`
  height: 100%;
  height: 380px;
  margin-top: 1rem;

  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #671ea7;
  }

  .MuiDataGrid-menuIconButton .MuiIconButton-label .MuiSvgIcon-fontSizeSmall {
    display: none;
  }
`;
