import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormDefault from '~/components/FormDefault';
import { ButtonForm, Container } from './styles';
import Loja from '~/components/Loja';
import Separator from '~/components/Separator';
import { Default } from './data';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { Col, Row } from 'react-bootstrap';
import { InputMesAno } from './components/InputMesAno';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaSpedFiscal } from './validations';
import { InputSelect } from './components/InputSelect';
import TableLogs from './components/TableLogs';
import { toast } from 'react-toastify';
import spedFiscalApi from './services';
import { useQuery } from 'react-query';
import {
  StateControllScreen,
  StateInfoProcesso,
  FormDataReactHook,
  RequestDataNotificacao,
  RequestDataCriaProcesso,
} from './protocols';
import getDateFormated from './utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { format, utcToZonedTime } from 'date-fns-tz';
import api from '~/services/api';
import { Context } from '~/context/auth';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import { useHistory } from 'react-router-dom';

const updateStateProperty = <T, K extends keyof T>(
  state: T,
  property: K,
  value: T[K],
) => {
  return { ...state, [property]: value };
};

const SpedFiscal: React.FC = () => {
  const { user } = useContext(Context);
  const { addScreenInfo } = useContext(InformacaoTelasAcessadasContext);
  const history = useHistory();

  const MySwal = withReactContent(Swal);
  const [lojas, setLojas] = useState<number[] | number>([]);
  const [infoProcesso, setInfoProcesso] = useState<StateInfoProcesso>({
    codProcesso: 0,
    tipoStatus: 0,
  });
  const [controlScreen, setControlScreen] = useState<StateControllScreen>({
    clearLoja: false,
    clearSelect: false,
    gerandoArquivo: false,
    showTableLog: false,
  });
  const [logs, setLogs] = useState<any[]>([]);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const [refetchLoopLogs, setRefetchLoopLogs] = useState<boolean>(false);
  const [page, setIsPage] = useState<number>(1);
  const {
    register,
    setValue,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schemaSpedFiscal),
  });

  useEffect(() => {
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    const mes = new Date().getMonth() + 1;
    const ano = new Date().getFullYear();
    setValue('mes', mes);
    setValue('ano', ano);
    setValue('finalidade', Default.finalidade[0]);
    setValue('perfil', Default.perfil[0]);
  }, []);

  const resetFormData = () => {
    setControlScreen((prevState) => ({
      ...prevState,
      clearLoja: true,
      clearSelect: true,
    }));
    setIsPage(1);
    setTimeout(() => {
      setControlScreen((prevState) => ({
        ...prevState,
        clearLoja: false,
        clearSelect: false,
      }));
    });
  };

  // Função para atualizar uma propriedade específica do estado
  const updateInfoProcesso = <K extends keyof StateInfoProcesso>(
    property: K,
    value: StateInfoProcesso[K],
  ) => {
    const updatedState = updateStateProperty(infoProcesso, property, value);
    // Atualizando o estado com o novo valor
    setInfoProcesso(updatedState);
  };

  const fetchLogs = useCallback(async () => {
    try {
      const { codProcesso } = infoProcesso;
      const getLogs = await spedFiscalApi.getLogs(codProcesso);
      const onlyLogs = getLogs.data.filter(
        (item: any) => !('tipo_status' in item),
      );
      const onlyStatus = getLogs.data.find(
        (item: any) => 'tipo_status' in item,
      );
      if (onlyStatus) {
        updateInfoProcesso('tipoStatus', onlyStatus.tipo_status);
        if (onlyStatus.tipo_status === 2) {
          setControlScreen((prevState) => ({
            ...prevState,
            gerandoArquivo: false,
          }));
          resetFormData();
          setRefetchLoopLogs(false);
        }
      }
      setLogs(onlyLogs);
      const paginasTotais = Math.ceil(onlyLogs.length / 5);
      setIsPage(paginasTotais);
    } catch (error) {
      toast.warn('Não foi possível buscar Logs do processo');
    }
  }, [isMounted, infoProcesso]);

  const { refetch: refetchLogs } = useQuery(
    `sped-fiscal-processo[${infoProcesso.codProcesso}]`,
    fetchLogs,
    {
      enabled: refetchLoopLogs,
      refetchInterval: 5000,
    },
  );

  const handleLojas = useCallback(
    async (Lojas: number | number[]) => {
      const newLojas = Array.isArray(Lojas) ? Lojas : new Array(Lojas);
      setLojas(newLojas);
    },
    [lojas],
  );

  const prepareData = (data: FormDataReactHook): RequestDataCriaProcesso => {
    return {
      cod_loja: lojas,
      num_mes: Number(data.mes),
      num_ano: Number(data.ano),
      tipo_finalidade: data.finalidade.value,
      tipo_perfil: data.perfil.value,
    };
  };

  const prepareDataNotification = useCallback(
    (link: string): RequestDataNotificacao => {
      const { codProcesso } = infoProcesso;
      const requestData = {
        link,
        cod_lojas: lojas,
        cod_processo: codProcesso,
      };
      return requestData;
    },
    [infoProcesso, lojas],
  );

  const createNotification = useCallback(
    async (link: string) => {
      const requestData = prepareDataNotification(link);
      await spedFiscalApi.generateNotification(requestData);
    },
    [prepareDataNotification],
  );

  const handleDownload = useCallback(async () => {
    setTimeout(() => toast.success('Efetuando download do arquivo...'), 150);
    try {
      const { codProcesso } = infoProcesso;
      const response = await spedFiscalApi.downloadZip(codProcesso);
      const blob = response;

      // Cria um URL para o blob
      const url = window.URL.createObjectURL(blob);

      // Cria um link <a> temporário
      const link = document.createElement('a');
      link.href = url;
      link.download = `sped-fiscal-${codProcesso}`;
      link.rel = 'noreferrer noopener';
      // Adiciona o link ao corpo do documento
      document.body.appendChild(link);

      // Simula o clique no link para iniciar o download
      link.click();
      createNotification(url);
      // Remove o link do corpo do documento
      document.body.removeChild(link);
      refetchLogs();
    } catch (error) {
      setTimeout(
        () => toast.warn('Não foi possível realizar o download do arquivo'),
        200,
      );
    }
  }, [infoProcesso]);

  useEffect(() => {
    if (infoProcesso.tipoStatus === 2) {
      toast.success('Processo concluído com sucesso!');
      handleDownload();
    }
  }, [infoProcesso.tipoStatus, infoProcesso.codProcesso]);

  function CreateTable(itens: any): void {
    const AlertTableHeader =
      '<tr><th>Loja</th><th>Data</th><th>PDV</th><th>STATUS LEITURA</th></tr>';
    const AlertTableBody = itens.map((item: any): string => {
      const zonedDate = utcToZonedTime(item.dta_venda, 'UTC');
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.cod_loja}${spaco}</td><td>${format(
        zonedDate,
        'dd/MM/yyyy',
      )}${spaco}</td><td>${item.num_pdv}${spaco}</td><td>${
        item.des_tipo_status
      }${spaco}</td></tr>`;
    });

    const AlertTable = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${AlertTableHeader}</thead>
                <tbody>${AlertTableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 20px'>
              Regularize a situação para prosseguir.
            </p>
            `;
    MySwal.fire({
      icon: 'info',
      width: 800,
      title:
        'Vendas abaixo não foram conferidas. \n Verifique na tela de "Conferência de Vendas"',
      html: String(AlertTable),
    });
  }

  function CreateTableWithLink(itens: any, tela: any): void {
    const AlertTableHeader =
      '<tr><th>Loja</th><th>Data</th><th>PDV</th><th>STATUS LEITURA</th></tr>';
    const AlertTableBody = itens.map((item: any): string => {
      const zonedDate = utcToZonedTime(item.dta_venda, 'UTC');
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.cod_loja}${spaco}</td><td>${format(
        zonedDate,
        'dd/MM/yyyy',
      )}${spaco}</td><td>${item.num_pdv}${spaco}</td><td>${
        item.des_tipo_status
      }${spaco}</td></tr>`;
    });

    const AlertTable = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${AlertTableHeader}</thead>
                <tbody>${AlertTableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 20px'>
              Regularize a situação para prosseguir.
            </p>
            `;
    MySwal.fire({
      icon: 'info',
      width: 800,
      title: `Vendas abaixo não foram conferidas.\nVerifique na tela de <a id="link-conferencia" style="cursor: pointer;">"Conferência de Vendas"</div>`,
      html: String(AlertTable),
      didRender: () => {
        const link = document.getElementById('link-conferencia');
        if (link) {
          link.addEventListener('click', () => {
            if (tela) {
              toTela(tela);
              Swal.close();
            } else {
              toast.warning(
                'Seu usuário não possui permissão para acessar a tela Conferência de Vendas. Consulte o usuário administrador do sistema.',
              );
            }
          });
        }
      },
    });
  }

  const verificaFechamento = async (): Promise<any> => {
    const { mes, ano } = getValues();
    const { dtaIni, dtaFim } = getDateFormated(mes, ano);
    const requestData = {
      dtaIni,
      dtaFim,
      codLoja: lojas,
    };

    const response = await spedFiscalApi.verificaFechamento(requestData);

    if (response.success) {
      return { itens: response.data, success: response.data.length > 0 };
    }
    return { itens: [], success: false };
  };

  const toTela = (tela: any) => {
    const telaAdd = {
      label: tela.label_menu,
      active: true,
      lib_icon: tela.lib_icon,
      des_icon: tela.des_icon,
      cod_tela: tela.cod_tela,
      url: tela.url,
      linkMaterial: tela.link_material,
      flg_abre_pesquisa: tela.flg_abre_pesquisa,
    };

    addScreenInfo(telaAdd);

    history.push('conferencia-venda-sat-nfce');
  };

  const onClick = handleSubmit(async (data) => {
    const { itens, success } = await verificaFechamento();
    let tela289 = null;
    if (success) {
      try {
        const { data: dataUserPages } = await api.get(
          `/telasModulo/${user?.cod_controle}`,
        );

        if (dataUserPages.data) {
          tela289 = dataUserPages.data
            .flatMap((modulo: any) => modulo.telas)
            .find((tela: any) => tela.cod_tela === 289);
        }
      } catch (error) {
        toast.warn(error);
      }
      CreateTableWithLink(itens, tela289);
      return;
    }
    try {
      setLogs([]);
      setControlScreen((prevState) => ({
        ...prevState,
        gerandoArquivo: true,
      }));
      const requestData = prepareData(data as FormDataReactHook);
      const result = await spedFiscalApi.createProcess(requestData);
      if (result.success && result.cod_processo) {
        updateInfoProcesso('codProcesso', result.cod_processo);
        setControlScreen((prevState) => ({
          ...prevState,
          showTableLog: true,
        }));
        setRefetchLoopLogs(true);
      } else {
        return toast.warn('Não foi possível salvar o Processo');
      }
    } catch (error) {
      setControlScreen((prevState) => ({
        ...prevState,
        showTableLog: false,
        gerandoArquivo: false,
      }));
      toast.error(error);
    }
  });

  const changeLabelSeparator = () => {
    const { codProcesso } = infoProcesso;
    if (controlScreen.showTableLog) {
      return `Logs da execução do processo #${codProcesso}`;
    }
    return 'Logs da execução do processo ';
  };

  return (
    <Container>
      <FormDefault
        codTela={285}
        title="Sped Fiscal"
        isNew={false}
        isClear={false}
        isSave
        hideFooter
        isCancel={false}
        isDelete={false}
        onSave={() => []}
        onCancel={() => []}
        isUpdate={false}
        onNew={() => []}
        onDelete={() => []}
        onClearFields={() => []}
        onReturnSearch={() => []}
      >
        <Row className="mb-2">
          <Col className="col-md-12 mb-2">
            <Loja
              disabled={controlScreen.gerandoArquivo}
              isMulti
              resetLojas={controlScreen.clearLoja}
              onChange={(value) => handleLojas(value)}
            />
          </Col>
          <Separator labelText="Parâmetros" color="black" />
          <Col md={0} style={{ marginRight: '1rem' }}>
            <InputMesAno
              disabled={controlScreen.gerandoArquivo}
              control={control}
              register={register}
              label="Mês/Ano"
              nameMes="mes"
              nameAno="ano"
              maxAno={9999}
              minAno={2024}
              maxLengthAno={4}
              maxLengthMes={2}
              isErrorAno={!!errors.ano}
              isErrorMes={!!errors.mes}
            />
          </Col>
          <Col md={5} style={{ marginRight: '1rem' }}>
            <InputSelect
              disabled={controlScreen.gerandoArquivo}
              control={control}
              options={Default.finalidade}
              register={register}
              setValue={setValue}
              clearCampo={controlScreen.clearSelect}
              placeholder="Selecione"
              label="Finalidade"
              name="finalidade"
              changeSelected={(value) => setValue('finalidade', value)}
              isError={!!errors.finalidade}
            />
          </Col>
          <Col md={5}>
            <InputSelect
              disabled={controlScreen.gerandoArquivo}
              control={control}
              options={Default.perfil}
              register={register}
              setValue={setValue}
              clearCampo={controlScreen.clearSelect}
              placeholder="Selecione"
              label="Perfil"
              name="perfil"
              changeSelected={(value) => setValue('perfil', value)}
              isError={!!errors.perfil}
            />
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            justifyItems: 'flex-end',
            width: '100%',
            marginTop: '2rem',
          }}
        >
          <button
            type="button"
            disabled={controlScreen.gerandoArquivo}
            style={{
              width: '200px',
              height: '40px',
              backgroundColor: '#8850bf',
              color: '#fff',
              border: 'none',
              borderRadius: '3px',
            }}
            onClick={() => {
              onClick();
            }}
          >
            <BsFillLightningChargeFill size={20} />
            GERAR ARQUIVO
          </button>
        </div>
        {controlScreen.showTableLog && (
          <>
            <Separator
              labelText={changeLabelSeparator()}
              fontSize="16px"
              color="black"
            />
            <TableLogs
              logs={logs}
              rowsLabel={controlScreen.showTableLog}
              pageAuto={page}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                justifyItems: 'flex-end',
                width: '100%',
                marginTop: '0.5rem',
              }}
            >
              <button
                type="button"
                disabled={infoProcesso.tipoStatus < 2}
                style={{
                  width: '200px',
                  marginRight: '0.75rem',
                  height: '40px',
                  fontSize: '16px',
                  backgroundColor: '#28a745',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '3px',
                }}
                onClick={() => handleDownload()}
              >
                {infoProcesso.tipoStatus >= 2
                  ? 'Baixar arquivos'
                  : 'Gerando arquivo(s)...'}
              </button>
              <button
                type="button"
                disabled={!controlScreen.showTableLog}
                style={{
                  width: '150px',
                  height: '40px',
                  fontSize: '16px',
                  backgroundColor: '#8850bf',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '3px',
                }}
                onClick={() => fetchLogs()}
              >
                Atualizar
              </button>
            </div>
          </>
        )}
      </FormDefault>
    </Container>
  );
};

export default SpedFiscal;
