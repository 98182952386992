import * as yup from 'yup';

export const schemaSpedFiscal = yup
  .object({
    mes: yup.string().required(),
    ano: yup.string().required(),
    finalidade: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .required(),
    perfil: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .required(),
  })
  .required();
