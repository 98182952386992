import { format } from 'date-fns';

const getDateFormated = (
  mes: number,
  ano: number,
): { dtaIni: string; dtaFim: string } => {
  const dataInicial = new Date(ano, mes - 1, 1);
  dataInicial.setHours(0, 0, 0, 0);
  const dataFinal = new Date(ano, mes, 0);
  dataFinal.setHours(23, 59, 59, 999);
  const dtaIni = format(dataInicial, "yyyy-MM-dd'T'HH:mm:ss.SSSX");
  const dtaFim = format(dataFinal, "yyyy-MM-dd'T'HH:mm:ss.SSSX");

  return { dtaIni, dtaFim };
};

export default getDateFormated;
