import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  .MuiDataGrid-selectedRowCount {
    color: transparent !important;
  }
  .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
  }

  .super-app-theme--header {
    background: #8850bf !important;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;

  &:nth-child(2) {
    margin-left: 200px;
  }

  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
`;

export const ButtonForm = styled.button`
  width: 300px,
  height: 40px,
  background-color: #8850bf,
  color: #fff,
  border: none,
  border-radius: 3px,

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
