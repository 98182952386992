export const Default = {
  finalidade: [
    {
      label: '0 - Remessa do Arquivo Original',
      value: 0,
    },
    {
      label: '1 - Remessa do Arquivo Substituto',
      value: 1,
    },
  ],
  perfil: [
    {
      label: 'A - Perfil A',
      value: 0,
    },
    {
      label: 'B - Perfil B',
      value: 1,
    },
    {
      label: 'C - Perfil C',
      value: 2,
    },
  ],
};
