import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { TableContainer } from './styles';

import { GridColumns } from '@material-ui/data-grid';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

const columns: GridColumns = [
  {
    field: 'cod_log',
    headerName: 'Seq',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'des_log',
    headerName: 'Log',
    width: 880,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
      const { row } = params;
      const log = row.des_log;
      return (
        <Tooltip title={log}>
          <span>{log}</span>
        </Tooltip>
      );
    },
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'dta_cadastro',
    headerName: 'Data',
    width: 190,
    sortable: false,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    renderCell: (params: GridCellParams) => {
      const { row } = params;
      const date = moment(row.dta_cadastro)
        .utcOffset('America/Sao_Paulo')
        .format('DD/MM/YYYYTHH:mm:ss[Z]');

      const dateFormated = date.replace('Z', '').replace('T', ' ');
      return <>{dateFormated}</>;
    },
  },
];
interface PropsTable {
  logs: any[];
  rowsLabel: boolean;
  pageAuto: number;
}
const TableLogs: React.FC<PropsTable> = ({ logs, rowsLabel, pageAuto }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setCurrentPage(pageAuto);
  }, [pageAuto]);

  return (
    <>
      <TableContainer>
        <DataGrid
          rowsPerPageOptions={[5]}
          rows={logs}
          columns={columns}
          page={currentPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          pageSize={5}
          disableColumnSelector
          disableColumnFilter
          disableSelectionOnClick
          getRowId={(row: any) => row.cod_log + row.dta_cadastro}
          localeText={{
            noRowsLabel: rowsLabel ? 'Buscando logs...' : '',
          }}
        />
      </TableContainer>
    </>
  );
};

export default TableLogs;
