import api from '~/services/api';
import {
  RequestDataCriaProcesso,
  CriaProcessoResponse,
  RequestDataNotificacao,
  ResponseApiLogs,
  RequestVerificaFechamento,
} from '../protocols';

const spedFiscalApi = {
  createProcess: async (
    requestData: RequestDataCriaProcesso,
  ): Promise<CriaProcessoResponse> => {
    const request = await api.post('/sped-fiscal', requestData);
    const { success, message, data } = request.data;

    return {
      success,
      cod_processo: data,
      message,
    };
  },
  getLogs: async (codProcesso: number): Promise<ResponseApiLogs> => {
    const request = await api.get('/sped-fiscal/logs', {
      params: {
        cod_processo: codProcesso,
      },
    });
    const { success, message, data } = request.data;

    return { success, message, data };
  },
  downloadZip: async (codProcesso: number): Promise<Blob> => {
    const request = await api.get('/sped-fiscal/download', {
      params: {
        cod_processo: codProcesso,
      },
      responseType: 'blob',
    });
    const response: Blob = request.data;
    return response;
  },
  generateNotification: async (
    requestData: RequestDataNotificacao,
  ): Promise<string> => {
    const request = await api.post('/sped-fiscal/notificacao', requestData);
    return request.data;
  },
  verificaFechamento: async (
    requestData: RequestVerificaFechamento,
  ): Promise<any> => {
    const request = await api.get('/verifica-fechamento', {
      params: requestData,
    });

    return request.data;
  },
};

export default spedFiscalApi;
