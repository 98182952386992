import { nanoid } from 'nanoid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ListProps } from '../protocols';
import { Content, Option } from '../styles';

export const Lista: React.FC<ListProps> = ({
  listaWidth,
  listaHeight,
  inputRef,
  setIsOpen,
  filter,
  setFilter,
  selected,
  setSelected,
  options,
  changeSelected,
}) => {
  const ITEM_TO_RENDER = 50;

  const [renderItem, setRenderItem] = useState(ITEM_TO_RENDER);

  const loadMore = useCallback(
    () => setRenderItem((prev) => prev + ITEM_TO_RENDER),
    [],
  );

  /**
   * wrapperRef
   * Ref da lista de opções utilizado para fechar a lista ao clicar fora
   */
  const wrapperRef = useRef<HTMLUListElement | null>(null);

  const handleScroll = useCallback(() => {
    if (
      Number(wrapperRef.current?.scrollTop) +
        Number(wrapperRef.current?.clientHeight) >=
      Number(wrapperRef.current?.scrollHeight)
    ) {
      loadMore();
    }
  }, [loadMore]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  /**
   * useCloseOptions
   * Fecha lista de opções ao clicar fora
   */
  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (inputRef) inputRef.current.value = '';
          setFilter('');
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);

  /**
   * handleClick
   * Seleciona item da lista
   */
  const handleClick = useCallback(
    (option: { label: string; value: number | string }) => {
      setSelected(option);
      changeSelected(option);
      setIsOpen(false);
    },
    [changeSelected, setIsOpen, setSelected],
  );

  /**
   * renderOptions
   * Renderiza lista de opções
   */
  const renderOptions = useCallback(() => {
    if (options !== undefined) {
      if (options.length <= 0) {
        return (
          <Option className="not-found">Nenhum registro encontrado.</Option>
        );
      }
      const newOptions = options.filter((option) =>
        option.label
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(filter.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
      );
      if (newOptions.length <= 0) {
        return (
          <Option key={nanoid()} className="not-found">
            Nenhum registro encontrado.
          </Option>
        );
      }

      return (
        <InfiniteScroll
          dataLength={renderItem}
          next={loadMore}
          hasMore={renderItem < newOptions.length}
          loader={<small>Carregando...</small>}
          height={listaHeight}
        >
          {newOptions.slice(0, renderItem).map((option) => (
            <Option
              key={String(option?.value)}
              title={option.label}
              className={selected?.value === option?.value ? 'selected' : ''}
              onClick={() => {
                handleClick(option);
                setFilter('');
              }}
              onKeyPress={() => {
                handleClick(option);
                setFilter('');
              }}
            >
              <span style={{ marginRight: '0.625rem' }}>
                {option.icon && option.icon}
              </span>
              {option.label}
            </Option>
          ))}
        </InfiniteScroll>
      );
    }
    return <Option className="not-found">Nenhum registro encontrado.</Option>;
  }, [
    filter,
    handleClick,
    inputRef,
    listaHeight,
    loadMore,
    options,
    renderItem,
    selected?.value,
    setFilter,
  ]);

  return (
    <Content listaWidth={listaWidth}>
      <ul ref={wrapperRef} style={{ height: listaHeight }}>
        {options && renderOptions()}
      </ul>
    </Content>
  );
};
